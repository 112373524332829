import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams, Link } from "react-router-dom";
import Stripe from "stripe";
import { post } from "../../api";

const SuccessView = (props) => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const [stripeSK, setStripeSK] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const { cypher_token } = useSelector((state) => state.initialApiReducer);
  let stripe;
  const session_id = searchParams.get("sid");
  const pre_transaction_id = searchParams.get("pti");

  useEffect(() => {
    if (session_id && cypher_token) {
      let token = atob(cypher_token);
      token = token.split("-");
      stripe = new Stripe(token[0]);
      setStripeSK(token[0]);
      fetchSessionDetails();
    }
  }, [cypher_token]);

  const initiateTransaction = async (session, subscription) => {
    let { payment_status } = session;
    const subscription_status = payment_status === "paid" ? 1 : 0;
    const subscriptionResponse = btoa(JSON.stringify(subscription));
    let response = await post(
      `/complete-subscription?pre_transaction_id=${pre_transaction_id}&post_transaction_id=${subscription.id}&payment_response=${subscriptionResponse}&subscription_status=${subscription_status}`
    );
    if (response?.status_code) {
      setMessage(response?.message);
    }
  };

  const fetchSessionDetails = async () => {
    const session = await stripe.checkout.sessions.retrieve(session_id);
    const subscription = await stripe.subscriptions.retrieve(
      session.subscription
    );
    let amount=subscription?.plan?.amount;
    amount= (amount/100);
    amount=`$${amount}`;
    setTotalAmount(amount);
    initiateTransaction(session, subscription);
  };

  return (
    <div className="subscription_success_page">
      {window.tdl && window.tdl.convert({
            amount: totalAmount,
            adv_sub: "darkroom checkout",
            adv_unique1: pre_transaction_id, // You can replace this with any unique identifier
      })}
      {totalAmount?<img src={`https://www.shareasale.com/sale.cfm?tracking=${pre_transaction_id}&amount=${totalAmount}&merchantID=160063&transtype=sale`} width="1" height="1" />:null}
      <div className="account_verified">
        <h3 style={{ color: "#fff" }}>{message}</h3>
        <p>
          Go back to <Link to="/"> Home Page</Link>
        </p>
      </div>
    </div>
  );
};

export default SuccessView;
