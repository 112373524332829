import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reportErrorRequest } from "../../redux/actions/reportIssueAction";
import { toast } from "react-toastify";

const ReportIssue = (props) => {
  const dispatch = useDispatch();
  const textArea = useRef();
  const { closeReportPopup } = props;
  const { video_id, seasion_id, episode_id, video_type } = useSelector(
    (state) => state.videoReducer
  );
  const {
    loginData: { user_id },
  } = useSelector((state) => state.authReducer);
  const { errorList } = useSelector((state) => state.reportIssueReducer);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedList, setSelectedList] = useState([]);
  const [formData, setFormData] = useState({
    video_id,
    seasion_id,
    episode_id,
    user_id,
    video_type,
  });
  useEffect(() => {
    if (errorList?.length > 0) {
      setSelectedList(errorList[selectedIndex]?.error_type_list);
    }
  }, [selectedIndex]);

  const clickHandler = (e, index) => {
    setSelectedList([]);
    setSelectedIndex(index);
  };

  const checkboxChange = (e, data) => {
    if (e.target.checked) {
      setFormData({
        ...formData,
        error_id: data.error_master_id,
        sub_error_id: data.id,
        error_message: "",
      });
    }
  };
  const reportError = (e) => {
    if (formData?.error_id) {
      const data = { ...formData, error_message: textArea?.current.innerHTML };
      dispatch(reportErrorRequest(data));
    }
    toast("Your Issue has been reported.");
    closeReportPopup(e);
  };

  return (
    <div className="error-report-container">
      <div className="content-wrapper">
        <div className="left-container">
          <div className="header">What's the issue ?</div>
          {errorList?.map((item, index) => {
            return (
              <div
                className={`list ${selectedIndex == index ? "active" : ""}`}
                key={item.error_title}
                onClick={(e) => clickHandler(e, index)}
              >
                <h6 className="list-title">{item.error_title}</h6>
                <p className="list-content">{item.error_description}</p>
              </div>
            );
          })}
        </div>
        <div className="right-container">
          <div className="top-section">
            <div className="header">
              <span>Select all that apply:</span>
              <span className="close" onClick={closeReportPopup}></span>
            </div>
            {selectedList?.map((item, index) => {
              return (
                <div className="list" key={item.error_master_id}>
                  <label className="checkbox-container">
                    <input
                      type="radio"
                      name="answer"
                      id={`check-${item.id}`}
                      onChange={(e) => checkboxChange(e, item)}
                    />
                    <span className="checkmark" id={`span-${item.id}`}></span>
                  </label>
                  <p>{item.error_type_title}</p>
                </div>
              );
            })}
          </div>
          <div className="text-container">
            <div className="text-area" contentEditable ref={textArea}></div>
            {/* <div className="send-btn" tabIndex={0} onClick={reportError}> */}
            <div
              className="send-btn"
              tabIndex={0}
              onClick={(e) => {
                reportError(e);
              }}
            >
              Send
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportIssue;
