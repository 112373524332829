import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { store } from "../configure-store";
import { getDeviceId } from "../../utils/utils";
import { KEYS } from "../../utils/constant";
import { getItem } from "../../utils/storage";
import { post } from "../../api";
import {
  GET_AUTH_REQUEST,
  GET_AUTH_SUCCESS,
  GET_AUTH_FAILURE,
  GET_LOGIN_REQUEST,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_FAILURE,
  GET_SIGNUP_REQUEST,
  LOGOUT,
  LOGOUT_SUCCESS,
  GET_FORGET_REQUEST,
  GET_FORGET_SUCCESS,
  GET_FORGET_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  SET_AUTH_DATA,
  LOGIN_EMPTY,
  LOGIN_DATA_EMPTY,
} from "../types";

function getEndPoint(type, payload) {
  switch (type) {
    case "guest":
      return "/login-guest";
    case "login":
      return "/login";
    case "signup":
      if (payload.signup_type == 2) {
        return "/sign-up-new";
        // return "/signup-with-subscription";
      } else {
        return "/sign-up-new";
      }
    case "forget":
      return "/forgot-password";
    case "reset":
      return "/reset-password";
    default:
      break;
  }
}

async function getAuthGuestApi(data) {
  let url = getEndPoint(data);
  const config = store.getState()["initialApiReducer"];
  const deviceId = getDeviceId();
  const headers = {
    "X-Device": "web",
    "X-Platform": "web",
    "X-Device-Id": deviceId,
    "X-Api-Key": config?.api_token || "",
  };
  try {
    const response = await post(url, {}, headers);
    return { token: response?.token, ...response?.data };
  } catch (error) {
    throw error;
  }
}
export async function loginApi(payload, accessType) {
  let url = getEndPoint(accessType, payload);
  const config = store.getState()["initialApiReducer"];
  const deviceId = getDeviceId();
  const headers = {
    "X-Device": "web",
    "X-Platform": "browser",
    "X-Device-Id": deviceId,
    "X-Api-Key": config?.api_token || "",
    "X-Device-Type": 1,
  };
  try {
    const formData =
      accessType === "signup"
        ? payload
        : { email: payload.email, password: payload.password };
    const response = await post(url, formData, headers);
    if (response.error) {
      return { error: response.error };
    } else
      return {
        rootUserDetail: {
          token: response?.bearer_token || response?.token,
          userToken: response?.user_token,
          user_id: response?.data?.user_id,
        },
        token: response?.bearer_token || response?.token,
        ...response?.data,
        userToken: response?.user_token,
        message: response?.message,
        status_code: response.status_code,
      };
  } catch (error) {
    throw error;
  }
}

async function forgetApi(payload, accessType) {
  let url = getEndPoint(accessType);
  const config = store.getState()["initialApiReducer"];
  const deviceId = getDeviceId();
  const headers = {
    "X-Device": "web",
    "X-Platform": "browser",
    "X-Device-Id": deviceId,
    "X-Api-Key": config?.api_token || "",
    "X-Ip-Address": config?.ip_address,
  };
  try {
    const formData = { email: payload.email };
    const response = await post(url, formData, headers);
    return { message: response?.message, status_code: response.status_code };
  } catch (error) {
    throw error;
  }
}
async function resetApi(payload, accessType) {
  let url = getEndPoint(accessType);
  const config = store.getState()["initialApiReducer"];
  const deviceId = getDeviceId();
  const headers = {
    "X-Device": "web",
    "X-Platform": "browser",
    "X-Device-Id": deviceId,
    "X-Api-Key": config?.api_token || "",
    "X-Ip-Address": config?.ip_address,
  };
  try {
    url = `${url}?new_password=${payload.password}&confirm_new_password=${payload.confirmPassword}&token=${payload.token}`;
    const response = await post(url, {}, headers);
    return { message: response?.message, status_code: response.status_code };
  } catch (error) {
    throw error;
  }
}

async function logoutApi(status) {
  let url = "/logout";
  const config = store.getState()["initialApiReducer"];
  const deviceId = getDeviceId();
  const userData = getItem(KEYS.USER_DETAILS);
  const userDetail = userData && JSON.parse(userData);
  const headers = {
    "X-Device": "web",
    "X-Platform": "browser",
    "X-Device-Id": deviceId,
    "X-Api-Key": config?.api_token || "",
  };
  try {
    const formData = {
      user_id: userDetail?.user_id,
      device: "web",
      platform: "browser",
    };
    const response = await post(url, formData, headers);
    return { token: response?.token, ...response?.data, status };
  } catch (error) {
    throw error;
  }
}

function* fetchAuthData(action) {
  try {
    const data = yield call(getAuthGuestApi, action.payload, action.accessType);
    yield put({ type: GET_AUTH_SUCCESS, data });
  } catch (e) {
    yield put({ type: GET_AUTH_FAILURE, message: e.message });
  }
}

function* loginApiCall(action) {
  try {
    const data = yield call(loginApi, action.payload, action.accessType);
    data.accessType = action.accessType;
    yield put({ type: GET_LOGIN_SUCCESS, data });
  } catch (e) {
    yield put({ type: GET_LOGIN_FAILURE });
  }
}

function* forgetApiCall(action) {
  try {
    const data = yield call(forgetApi, action.payload, action.accessType);
    yield put({ type: GET_FORGET_SUCCESS, data });
  } catch (e) {
    yield put({ type: GET_FORGET_FAILURE });
  }
}

function* resetApiCall(action) {
  try {
    const data = yield call(resetApi, action.payload, action.accessType);
    yield put({ type: RESET_PASSWORD_SUCCESS, data });
  } catch (e) {
    yield put({ type: RESET_PASSWORD_FAILURE });
  }
}

function* logout(action) {
  try {
    let data;
    // if(action.status){
    data = yield call(logoutApi, action.status, action.accessType);
    data.accessType = action.accessType;
    data.status = action.status;
    // }
    yield put({ type: LOGOUT_SUCCESS, data });
  } catch (e) {
    yield put({ type: GET_LOGIN_FAILURE });
  }
}

function* setAuthData(action) {
  try {
    yield put({
      type: GET_LOGIN_SUCCESS,
      data: {
        token: action?.data?.bearer_token || action?.data?.token,
        ...(action?.data?.data && action?.data?.data),
        ...(!action?.data?.data && action?.data),
        userToken: action?.data?.user_token || action?.data?.userToken,
        message: action?.data?.message,
        status_code: action?.data?.status_code,
      },
    });
  } catch (e) {
    yield put({ type: GET_LOGIN_FAILURE });
  }
}

export function* authSaga(data) {
  yield takeEvery(GET_AUTH_REQUEST, fetchAuthData);
  yield takeEvery(GET_LOGIN_REQUEST, loginApiCall);
  yield takeEvery(GET_SIGNUP_REQUEST, loginApiCall);
  yield takeEvery(LOGOUT, logout);
  yield takeEvery(GET_FORGET_REQUEST, forgetApiCall);
  yield takeEvery(RESET_PASSWORD_REQUEST, resetApiCall);
  yield takeEvery(SET_AUTH_DATA, setAuthData);
  yield takeLatest(LOGIN_EMPTY, function* () {
    yield put({ type: LOGIN_DATA_EMPTY });
  });
}

export default authSaga;
