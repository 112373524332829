import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import Header from '../../Component/Header';
import { bannerslide, serviceslider, newarrivalslider, browseallsection, trendingslider, filmmatterslider, importantitemslider } from './theme';
import Banner from './banner';
import { toast } from 'react-toastify';

import { continueWatchingEmpty, getBanner, getContinueWatchingData, getHomeData, getStillLookingData, getWatchListData, makeHomeEmpty, watchListEmpty } from '../../redux/actions/homeAction';
import { getInitialApiRequest } from '../../redux/actions/initialApiAction';
import { IRootState } from '../../redux/reducer';
import List from '../../Component/List';
import { IHomeData } from '../../model/bannerModel';
import Footer from '../../Component/Footer';
import { IHeaderinitialState } from '../../redux/reducer/headerReducer';
import { setLeftSideMenuActive, setRightSideMenuActive } from '../../redux/actions/headerAction';
import Loader from "../../Component/Loader";
import WishListPopup from '../../Component/Popup/wishlistPopup';
import RemoveWishlistPopup from '../../Component/Popup/removeWishlistPopup';
import { createVideoListRequest, removeVideoListRequest, openWishlistRequest, openRemoveWishlistRequest, toggleToastBool } from '../../redux/actions/createListAction';
import { ICreateListState } from '../../model/createList';
import LoginAlertPopup from '../../Component/Popup/loginAlertPopup';

declare global {
  interface Window {
    $: any;
    end: any;
    player: any;
    adPlayer: any;
  }
}

const Home = () => {
  const [count, setCount] = React.useState(0);
  const [loginAlertBool, setLoginAlertBool] = React.useState('');
  const [advancedClick, setAdvancedClick] = React.useState(false);
  const { createVideoListBool, message } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);
  const dispatch = useDispatch();
  const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
  const { homeData, number_of_rows, watchListDataArr, continueWatchingDataArr } = useSelector<IRootState, any>((state) => state.homeReducer);
  const { left_active, right_active } = useSelector<IRootState, IHeaderinitialState>((state) => state.headerReducer);
  const { isAddWishlistOpen, isRemoveWishlistOpen, video_id } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);
  const { loginData, logout } = useSelector<IRootState, any>((state) => state.authReducer);
  const $ = window.$;
  const ref = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!dev_base_url || !live_base_url) {
      dispatch(getInitialApiRequest());
      setCount(0);
    }
    window.player?.destroy();
    window.adPlayer?.destroy();
  }, []);

  useEffect(() => {
    if (left_active && advancedClick) {
      ref.current?.focus()
      advancedSearchClick()
    }
  }, [left_active, advancedClick])
  useEffect(() => {
    setCount(0);
    window.scrollTo(0, 0);
    if (dev_base_url) {
      bannerslide();
      dispatch(getBanner(loginData?.user_id));
      if (!continueWatchingDataArr?.length && loginData?.user_id) {
        dispatch(getContinueWatchingData(loginData?.user_id))
      }
      if (!watchListDataArr?.length && loginData?.user_id) {
        dispatch(getWatchListData(loginData?.user_id))
      }
      if (!loginData?.user_id) {
        dispatch(getHomeData({ count: 0, user_id: loginData?.user_id }));
      }
    }
    return () => {
      dispatch(continueWatchingEmpty());
      dispatch(watchListEmpty())
      dispatch(makeHomeEmpty());
      dispatch(toggleToastBool());
    };

  }, [dev_base_url, logout]);

  useEffect(() => {
    if (!dev_base_url || !homeData?.length)
      return;
    serviceslider();
    newarrivalslider();
    browseallsection();
    filmmatterslider();
    trendingslider();
    importantitemslider();
    if (logout) {
      // toast('Logged out successfully');
    }
    if (homeData?.length === number_of_rows + continueWatchingDataArr?.length + watchListDataArr?.length && !Array.isArray(homeData[homeData?.length - 2])) {
      dispatch(getStillLookingData())
    }
  }, [homeData])

  useEffect(() => {

    if (Array.isArray(watchListDataArr))
      dispatch(getHomeData({ count, user_id: loginData?.user_id }));
  }, [watchListDataArr])

  const getPaginationData = () => {
    setCount(count + 1);
    dispatch(getHomeData({ count: count + 1, user_id: loginData?.user_id }));
  }

  const toggleWishlist = (val: boolean, image: string | undefined, video_id: number) => {
    dispatch(openWishlistRequest(val, image, video_id));
    dispatch(toggleToastBool());
  }

  const toggleRemoveWishlist = (val: boolean, video_id: number, watch_list_id: number) => {
    dispatch(openRemoveWishlistRequest(val, video_id));
  }

  const saveVideoList = (watchListId: string) => {
    dispatch(createVideoListRequest(video_id, watchListId))
  }
  const removeVideoList = (video_id: number) => {
    dispatch(removeVideoListRequest(video_id));
  }
  const advancedSearchClick = () => {
    setAdvancedClick(true)
  }
  useEffect(() => {
    if (createVideoListBool) {
      toast(message);
      dispatch(continueWatchingEmpty());
      dispatch(watchListEmpty())
      dispatch(makeHomeEmpty());
      dispatch(toggleToastBool());
      dispatch(getContinueWatchingData(loginData?.user_id));
      dispatch(getBanner(loginData?.user_id));
      dispatch(getHomeData({ count: 0, user_id: loginData?.user_id }, true));
      dispatch(getWatchListData(loginData?.user_id))
      setCount(0)
    } else if (message) {
      toast(message);
    }
  }, [createVideoListBool, message])
  return (<>
    {/* <ToastContainer /> */}
    {homeData && homeData.length > 0 ?
      <>

        <Header ref={ref} />
        <Banner toggleWishlist={toggleWishlist} toggleRemoveWishlist={toggleRemoveWishlist} setLoginAlertBool={setLoginAlertBool} />
        {loginAlertBool ? <LoginAlertPopup setLoginAlertBool={setLoginAlertBool} loginAlertBool={loginAlertBool} message={loginAlertBool} /> : <></>}
        {isAddWishlistOpen ? <WishListPopup toggleWishlist={toggleWishlist} saveVideoList={saveVideoList} /> : <></>}
        {isRemoveWishlistOpen ? <RemoveWishlistPopup toggleRemoveWishlist={toggleRemoveWishlist} removeVideoList={removeVideoList} /> : <></>}
        <div className="home_page">
          <div className="home_main">
            <InfiniteScroll
              style={{ 'height': 'inherit', overflow: 'hidden' }}
              dataLength={homeData.length}
              next={() => {
                if (((homeData?.length < number_of_rows + watchListDataArr?.length + continueWatchingDataArr?.length) && loginData) || (homeData?.length < number_of_rows)) {
                  getPaginationData();
                }
              }}
              hasMore={true}
              loader={(((homeData?.length < number_of_rows + watchListDataArr?.length + continueWatchingDataArr?.length) && loginData) || (homeData?.length < number_of_rows)) ? <Loader type="landscape" /> : <></>}
            >
              {homeData?.length && homeData?.map((item: IHomeData, index: number) => <List key={item.id} item={item} index={index} toggleWishlist={toggleWishlist} toggleRemoveWishlist={toggleRemoveWishlist} setLoginAlertBool={setLoginAlertBool} advancedSearchClick={advancedSearchClick} />)}
            </InfiniteScroll>

          </div>
        </div>
        <Footer />
        <div className="whiteOverlay2" onClick={() => { dispatch(setLeftSideMenuActive(false)); setAdvancedClick(false); }} style={{ display: left_active ? 'block' : '' }}></div>
        <div className="whiteOverlay3" onClick={() => dispatch(setRightSideMenuActive(false))} style={{ display: right_active ? 'block' : '' }}></div>
      </> : <Loader type="home" />}</>
  )
}

export default Home;
