import './App.css';

import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux'
import { store } from './redux/configure-store';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Home from './Container/Home';
import MovieDetail from './Container/MovieDetail';
import SignIn from './Container/Auth';
import Player from './Container/Player';
import Category from './Container/Category';
import {
  CONTENT_DETAIL,
  SIGN_IN,
  PLAYER,
  CATEGORY,
  PRIVACYPOLICY,
  SEARCH,
  SIGN_UP,
  ACCOUNT_VERIFICATION,
  RESET,
  ACTIVATETV,
  PROFILE,
  ORACLE,
  USERLIST,
  ADDPROFILE,
  STILLLOOKINGCONTENT,
  COMINGSOON,
  WATCH_HISTORY,
  SUGGESTED_VIEW,
  DEVICES,
  PLAN_PAGE,
  MEMBERSHIP,
  TERMS,
  ABOUT,
  FAQ,
  HELP,
  TRANSACTION_SUCCESS,
  TRANSACTION_CANCEL,
  ACCEPT_SHARE_WATCHLIST
} from './routes/routesConstant';
import InitialComponent from './InitialComponent';
import PrivacyPolicy from './Container/PrivacyPolicy';
import Search from './Container/Search';
import AccountVerification from './Container/AccountVerification';
import Reset from './Container/Auth/Reset';
import ActivateTV from './Container/ActivateTV';
import Profile from './Container/Profile';
import Tool from './Container/Tool';
import UserList from './Container/UserList';
import AddProfile from './Container/AddProfile';
import StillLookingContent from './Container/StillLookingContent';
import ComingSoon from './Container/ComingSoon';
import WatchHistory from "./Container/WatchHistory";
import SuggestedView from "./Container/SuggestedView";
import DeviceView from "./Container/DeviceView"
import PlanView from "./Container/Plan";
import MembershipView from './Container/MembershipView';
import About from './Container/About';
import Faq from './Container/Faq';
import Terms from './Container/Terms';
import Help from './Container/Help';
import SuccessView from './Container/Success';
import CancelView from './Container/Cancel';
import AcceptSharedWatchlist from './Container/AcceptSharedWatchlist';
const routerList = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: CONTENT_DETAIL,
    element: <MovieDetail />,
  },
  {
    path: SIGN_IN,
    element: <SignIn />,
  },
  {
    path: SIGN_UP,
    element: <SignIn />,
  },
  {
    path: PLAYER,
    element: <Player />,
  },
  {
    path: CATEGORY,
    element: <Category />,
  },
  {
    path: `${SEARCH}`,
    element: <Search />,
  },
  {
    path: `${PRIVACYPOLICY}`,
    element: <PrivacyPolicy />,
  },
  {
    path: `${ABOUT}`,
    element: <About />,
  },
  {
    path: `${HELP}`,
    element: <Help />,
  },
  {
    path: `${FAQ}`,
    element: <Faq />,
  },
  {
    path: `${TERMS}`,
    element: <Terms />,
  },
  {
    path: `${ACCOUNT_VERIFICATION}`,
    element: <AccountVerification />,
  },
  {
    path: `${RESET}`,
    element: <Reset />,
  },
  {
    path: `${ACTIVATETV}`,
    element: <ActivateTV />,
  },
  {
    path: `${PROFILE}`,
    element: <Profile />,
  },
  {
    path: `${ORACLE}`,
    element: <Tool />,
  },
  {
    path: `${USERLIST}`,
    element: <UserList />,
  },
  {
    path: `${ADDPROFILE}`,
    element: <AddProfile />,
  },
  {
    path: `${STILLLOOKINGCONTENT}`,
    element: <StillLookingContent />,
  },
  {
    path: `${COMINGSOON}`,
    element: <ComingSoon />,
  },
  {
    path: `${WATCH_HISTORY}`,
    element: <WatchHistory />
  },
  {
    path: `${SUGGESTED_VIEW}`,
    element: <SuggestedView />
  },
  {
    path: `${DEVICES}`,
    element: <DeviceView />
  },
  {
    path: `${PLAN_PAGE}`,
    element: <PlanView />
  },
  {
    path: `${MEMBERSHIP}`,
    element: <MembershipView />
  },
  {
    path: `${TRANSACTION_SUCCESS}`,
    element: <SuccessView />
  },
  {
    path: `${TRANSACTION_CANCEL}`,
    element: <CancelView />
  },
  {
    path: `${ACCEPT_SHARE_WATCHLIST}`,
    element: <AcceptSharedWatchlist />
  }
];
const router = createBrowserRouter(createRoutesFromElements(<>
  {routerList.map((item) => <Route key={item.path} path={item.path} element={item.element} />)}
</>
))

function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <InitialComponent router={router} />
    </Provider>
  );
}

export default App;
