import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import {
  getPlaybackDataRequest,
  emptyPlaybackData,
  reportPlaybackError,
  reportHeartBeat,
} from "../../redux/actions/videoAction";
import { updateUserHistory } from "../../redux/actions/userHistoryAction";
import { getInitialApiRequest } from "../../redux/actions/initialApiAction";
import VideoJSPlayer from "./player.videojs";
import AdWrapper from "./player.ad";
import PausedBanner from "./PausedBanner";
import ReportIssue from "./ReportIssue";
import { getDeviceId, casheBuster } from "../../utils/utils";
import { PLAYER } from "../../routes/routesConstant";

const Player = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loginData: { user_id },
  } = useSelector((state) => state.authReducer);
  const { episodeDetailData, seriesDetailData } = useSelector(
    (state) => state.movieDetailReducer
  );
  const { dev_base_url, live_base_url, latitude, longitude, ip_address } =
    useSelector((state) => state.initialApiReducer);
  const {
    play_url,
    video_title,
    trailer_url,
    video_subtitles = [],
    video_languages = [],
    video_genres = [],
    next_video,
    episode_skip_duration = [],
    play_time,
    total_time,
    left_time,
    episode_number,
    season_number,
  } = useSelector((state) => state.videoReducer);
  const { adData } = useSelector((state) => state.adReducer);
  const skip_data = episode_skip_duration || [];
  const [searchParams] = useSearchParams();
  const [playerRef, setPlayerRef] = useState(null);
  const [audios, setAudios] = useState([]);
  const [showControls, setShowControls] = useState(false);
  const [subtitles, setSubtitles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [playStatus, setPlayStatus] = useState(false);
  const [muteStatus, setMuteStatus] = useState(false);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [remainingTime, setRemainingTime] = useState("00:00");
  const [remainingTimeSeconds, setRemainingTimeSeconds] = useState(0);
  const [userPause, setUserPause] = useState(false);
  const [loader, showLoader] = useState(false);
  const [selectedSubtitle, setSelectedSubtitle] = useState("");
  const [subtitlePopup, setSubtitlePopup] = useState(false);
  const [isSkipIntro, setIsSkipIntro] = useState(false);
  const [isNextVideo, setIsNextVideo] = useState(false);
  const [skipIntroIndex, setSkipIntroIndex] = useState(-1);
  const [showEpisodeList, setShowEpisodeList] = useState(false);
  const [cueText, setCueText] = useState("");
  const [volumeClicked, setVolumeClicked] = useState(false);
  const [sliderHeight, setSliderHeight] = useState(0);
  const [showPauseBanner, setShowPauseBanner] = useState(false);
  const [showReportIssue, setShowReportIssue] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  // ad state start
  const [adPlayStatus, setAdPlayStatus] = useState(false);
  const [adProgress, setAdProgress] = useState(0);
  const [adCurrentTime, setAdCurrentTime] = useState("00:00");
  const [adStarted, setAdStarted] = useState(false);
  const [adRef, setAdRef] = useState();
  const adSeekbarProgressRef = useRef();
  const adSeekbarRef = useRef();
  const adContainer = useRef();
  const adStatus = useRef();
  // ad state end
  // const playerDomRef = useRef();
  const prevTime = useRef(0);
  const heartBeatTime = useRef();
  const mouseTracker = useRef();
  const seekbarRef = useRef();
  const seekbarProgressRef = useRef();
  const seekTimeout = useRef();
  const pauseTimeout = useRef();
  const controlsTimeout = useRef();
  const isPlayer = useRef();
  const videoId = searchParams.get("video_id") || "";
  const video_type = searchParams.get("video_type") || "";
  const seasonId = searchParams.get("season_id") || "";
  const episodeId = searchParams.get("episode_id") || "";
  const content_type = searchParams.get("content_type") || "";
  useEffect(() => {
    if (!dev_base_url || !live_base_url) {
      dispatch(getInitialApiRequest());
    }
    return () => {
      playerRef?.destroy();
      adRef?.destroy();
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
      setAdRef(null);
      setPlayerRef(null);
    };
  }, []);

  useEffect(() => {
    document.removeEventListener("visibilitychange", visibilityChangeHandler);
    playerRef?.destroy();
    adRef?.destroy();
    isPlayer.current = false;
  }, [location]);

  useEffect(() => {
    if (playerRef) {
      const volume = localStorage.getItem("volume") || 1;
      const height = localStorage.getItem("sliderHeight") || 0;
      if (volume > 0) {
        setMuteStatus(false);
      } else {
        setMuteStatus(true);
      }
      playerRef?.setVolume(volume);
      setSliderHeight(height);
      document.addEventListener("visibilitychange", visibilityChangeHandler);
    }
  }, [playerRef]);

  useEffect(() => {
    const url = content_type === "trailer" ? trailer_url : play_url;
    if (url && !isPlayer.current) {
      const player = new VideoJSPlayer({
        url,
        videoId: "video-player",
        preload: "auto",
        muted: false,
        autoplay: true,
        isEncrypted: false,
        licenseUrl: "",
        licenseToken: "",
        drmName: "",
        callback,
        continueWatchTime: total_time - left_time,
      });
      player.init();
      setPlayerRef(player);
      isPlayer.current = true;
      if (content_type !== "trailer") {
        setSubtitles(video_subtitles);
        const adUrl = getAdData();
        const ad = new AdWrapper({
          adUrl,
          adCallback,
          videoId: "video-player",
          playerContainer: "player-container",
          adContainer: "ad-container",
        });
        setAdRef(ad);
      }
      dispatch(
        updateUserHistory({
          user_id,
          video_type,
          video_id: videoId,
          video_title,
          season_id: season_number,
          episode_id: episode_number,
        })
      );
    }
    // return () => {
    //   playerRef?.destroy();
    //   adRef?.destroy();
    //   document.removeEventListener("visibilitychange", visibilityChangeHandler);
    // };
  }, [play_url, trailer_url]);

  useEffect(() => {
    // if (user_id) {
    if (dev_base_url && live_base_url) {
      dispatch(
        getPlaybackDataRequest({
          user_id,
          video_type: video_type,
          video_id: videoId,
          season_id: seasonId,
          episode_id: episodeId,
        })
      );
    }
    setIsNextVideo(false);
    setIsSkipIntro(false);
    setSkipIntroIndex(-1);
    prevTime.current = 0;
    heartBeatTime.current = 0;
    // }
  }, [
    user_id,
    video_type,
    videoId,
    seasonId,
    episodeId,
    dev_base_url,
    live_base_url,
  ]);

  useEffect(() => {
    // if (selectedSubtitle) {
    enableSubtitle();
    // }
  }, [selectedSubtitle]);

  // useEffect(() => {
  //   if (showControls) {
  //     playerRef?.playerDom?.classList.remove("normal");
  //     playerRef?.playerDom?.classList.add("top");
  //   } else {
  //     playerRef?.playerDom?.classList.remove("top");
  //     playerRef?.playerDom?.classList.add("normal");
  //   }
  // }, [showControls]);

  const visibilityChangeHandler = () => {
    if (!window.location?.href?.includes("/player")) {
      playerRef?.destroy();
      adRef?.destroy();
      return;
    }
    if (document.visibilityState === "visible") {
      if (adStatus.current) {
        adRef?.play();
        playerRef?.pause();
      } else {
        playerRef?.play();
        setShowPauseBanner(false);
      }
    } else {
      playerRef?.pause();
      adRef?.pause();
    }
  };

  const getAdData = () => {
    let url = "";
    if (adData?.[0]?.adv_url) {
      url = adData?.[0]?.adv_url;
      url = url.replace("[CACHEBUSTER]", casheBuster());
      url = url.replace("[UA]", encodeURIComponent(navigator.userAgent));
      url = url.replace("[DID]", encodeURIComponent(getDeviceId()));
      url = url.replace("[CONTENT_ID]", videoId);
      url = url.replace("[CONTENT_LENGTH]", total_time);
      url = url.replace("[CONTENT_TITLE]", encodeURIComponent(video_title));
      url = url.replace(
        "[SITE_PAGE]",
        encodeURIComponent(window.location.href)
      );
      if (video_genres?.length) {
        let genre = video_genres?.map((genre) => genre.genre_name).join(",");
        url = url.replace("[CONTENT_GENRE]", encodeURIComponent(genre));
      }
      if (video_languages?.length) {
        let language = video_languages
          ?.map((language) => language.language_name)
          .join(",");
        url = url.replace("[CONTENT_LANGUAGE]", encodeURIComponent(language));
      }
      url =
        (episode_number && url.replace("[CONTENT_EPISODE]", episode_number)) ||
        url;
      url =
        (season_number && url.replace("[CONTENT_SEASON]", season_number)) ||
        url;
      const series_id = seriesDetailData?.[0]?.series_id || "";
      url = (series_id && url.replace("[CONTENT_SERIES]", series_id)) || url;
      url = url.replace("[APP_VERSION]", "3.0.0");
      url = (play_url && url.replace("[CONTENT_URL]", play_url)) || url;
      url = (latitude && url.replace("[LAT]", latitude)) || url;
      url = (longitude && url.replace("[LON]", longitude)) || url;
      url = (ip_address && url.replace("[IP]", ip_address)) || url;
    }
    return url;
  };

  const toHHMMSS = (time) => {
    if (!time) {
      return "00:00";
    }
    let sec_num = parseInt(time, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    if (hours > 0) {
      return hours + ":" + minutes + ":" + seconds;
    }
    if (hours == 0) {
      return minutes + ":" + seconds;
    }
  };

  const checkNextideo = (ct, dt) => {
    if (dt - ct <= 30 && dt - ct >= 25 && !isNextVideo) {
      setIsNextVideo(true);
    }
    if (dt - ct < 25 && isNextVideo) {
      setIsNextVideo(false);
    }
  };

  const checkSkipIntro = (ct) => {
    if (
      ct >= episode_skip_duration[0]?.skip_from &&
      ct <= episode_skip_duration[0]?.skip_to
    ) {
      setIsSkipIntro(true);
      setSkipIntroIndex(0);
    } else if (
      ct >= episode_skip_duration[1]?.skip_from &&
      ct <= episode_skip_duration[1]?.skip_to
    ) {
      setIsSkipIntro(true);
      setSkipIntroIndex(1);
    } else {
      setIsSkipIntro(false);
    }
  };

  const callback = (event, ct, dt) => {
    switch (event) {
      case "TIMEUPDATE":
        setProgress((ct / dt) * 100);
        setCurrentTime(toHHMMSS(ct));
        setRemainingTime(toHHMMSS(dt - ct));
        setRemainingTimeSeconds(dt - ct);
        checkNextideo(ct, dt);
        checkSkipIntro(ct);
        prevTime.current = ct;
        if (
          Date.now() - heartBeatTime.current > 5000 &&
          content_type !== "trailer"
        ) {
          heartBeatTime.current = Date.now();
          dispatch(
            reportHeartBeat({
              user_id,
              video_id: videoId,
              video_type,
              season_id: seasonId,
              episode_id: episodeId,
              play_time: ct,
            })
          );
        }
        break;
      case "LOADEDDATA":
        setCurrentTime(toHHMMSS(ct));
        setRemainingTime(toHHMMSS(dt));
        setAudios(playerRef?.getAudioTracks());
        const ss = localStorage.getItem("subtitle");
        setSelectedSubtitle(ss);
        break;
      case "PLAYING":
        setPlayStatus(true);
        showLoader(false);
        // adContainer?.current?.classList?.remove("add-overlay");
        break;
      case "LOADEDMETADATA":
        showLoader(true);
        // playerDomRef.current = playerRef?.playerDom;
        // console.log(playerDomRef.current);
        // setShowControls(true);
        break;
      case "WAITING":
        showLoader(true);
        break;
      case "ENDED":
        nextVideo();
        break;
      default:
        break;
    }
  };

  const backClick = (e) => {
    e.stopPropagation();
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setFullscreen(false);
    } else {
      dispatch(emptyPlaybackData());
      window.history.back();
    }
  };

  const playpause = () => {
    if (playerRef?.isPaused()) {
      playerRef?.play();
      setPlayStatus(true);
      setUserPause(false);
    } else {
      playerRef?.pause();
      setPlayStatus(false);
      setUserPause(true);
      pauseHandler();
    }
  };

  const pauseHandler = () => {
    if (pauseTimeout?.current) {
      clearTimeout(pauseTimeout.current);
    }
    pauseTimeout.current = setTimeout(() => {
      setShowPauseBanner(true);
    }, 120000);
  };

  const rewind = (e) => {
    e.stopPropagation();
    if (playerRef) {
      playerRef.rewind(10);
    }
  };

  const forward = (e) => {
    e.stopPropagation();
    if (playerRef) {
      playerRef.forward(10);
    }
  };

  const volume = (event) => {
    event.stopPropagation();
    setVolumeClicked(!volumeClicked);
    setSubtitlePopup(false);
  };

  const nextVideo = (e) => {
    e.stopPropagation();
    if (next_video) {
      // playerRef?.destroy();
      // adRef?.destroy();
      // isPlayer.current = false;
      setAdStarted(false);
      const {
        video_id,
        video_type,
        seasion_id = "",
        episode_id = "",
      } = next_video;
      if (video_type == 2) {
        navigate(
          `/player?video_id=${video_id}&video_type=${video_type}&season_id=${seasion_id}&episode_id=${episode_id}`
        );
      } else {
        navigate(`/player?video_id=${video_id}&video_type=${video_type}`);
      }
    }
  };

  const episodes = (e) => {
    e.stopPropagation();
    setSubtitlePopup(false);
    setVolumeClicked(false);
    if (showEpisodeList) setShowEpisodeList(false);
    else setShowEpisodeList(true);
  };

  const subtitleClick = (e) => {
    e.stopPropagation();
    setSubtitlePopup(!subtitlePopup);
    setVolumeClicked(false);
  };

  const skipIntro = (e) => {
    e.stopPropagation();
    if (skipIntroIndex > -1) {
      let skipIntroData =
        episode_skip_duration && episode_skip_duration[skipIntroIndex];
      const skipTime = skipIntroData?.skip_to;
      if (skipTime) {
        playerRef.seek(skipTime);
        setTimeout(() => {
          setIsSkipIntro(false);
        }, 2000);
      }
    }
  };

  const reportError = () => {
    dispatch(
      reportPlaybackError({
        user_id: user_id,
        video_type: video_type,
        video_id: videoId,
        season_id: seasonId,
        episode_id: episodeId,
      })
    );
  };

  const seekbarClick = (e) => {
    playerRef?.pause();
    let clickPosition =
      ((e.clientX - e.currentTarget.getBoundingClientRect().left) /
        e.currentTarget.getBoundingClientRect().width) *
      100;
    let clickedTime =
      (parseInt(clickPosition, 10) * parseInt(playerRef?.duration(), 10)) / 100;
    setProgress(parseInt(clickPosition, 10));
    playerRef?.seek(clickedTime);
    if (!userPause) {
      playerRef?.play();
    }
  };

  const subtitleItemClick = (event, item) => {
    event.stopPropagation();
    if (item && selectedSubtitle != item.language_short_name) {
      localStorage.setItem("subtitle", item.language_short_name);
      setSelectedSubtitle(item.language_short_name);
    } else if (item && selectedSubtitle == item.language_short_name) {
      localStorage.setItem("subtitle", "");
      setSelectedSubtitle("");
    }
  };

  const enableSubtitle = () => {
    if (subtitles) {
      playerRef?.setDefaultVttSubtitle(subtitles, selectedSubtitle);
    }
  };

  const episodeClick = (e, episode) => {
    setShowEpisodeList(false);
    seekbarProgressRef.current.style.width = 0;
    navigate(
      `${PLAYER}?video_id=${episode.video_id}&video_type=2&season_id=${episode.seasion_id}&episode_id=${episode.episode_id}`
    );
  };

  const createEpisodeList = () => {
    return episodeDetailData?.map((episode) => (
      <div
        className="list-item"
        key={`${episode.video_id}-${episode.episode_number}`}
        onClick={(e) => episodeClick(e, episode)}
      >
        <img src={episode.episode_image} alt={episode.episode_number} />
        <label>Episode {episode.episode_number}</label>
      </div>
    ));
  };

  const volumeSliderClick = (e) => {
    e.stopPropagation();
    let clickPosition =
      ((e.clientY - e.currentTarget.getBoundingClientRect().top) /
        e.currentTarget.getBoundingClientRect().height) *
      100;
    clickPosition = 100 - clickPosition;
    let volume = parseInt(clickPosition, 10) / 100;
    volume = Number.parseFloat(volume).toFixed(1);
    localStorage.setItem("volume", volume);
    localStorage.setItem("sliderHeight", clickPosition);
    playerRef?.setVolume(volume);
    setSliderHeight(clickPosition);
    if (volume > 0) {
      setMuteStatus(false);
    } else {
      setMuteStatus(true);
    }
  };

  const backButtonClick = (e) => {
    e.stopPropagation();
    setShowPauseBanner(false);
    playerRef?.play();
  };

  const onMouseEnterHandler = (e) => {
    mouseTracker.current = true;
  };

  const onMouseMoveHandler = (e) => {
    if (mouseTracker.current) {
      if (!playerRef?.isPaused()) {
        playerRef?.pause();
      }
      let clickPosition =
        ((e.clientX - e.currentTarget?.getBoundingClientRect().left) /
          e.currentTarget?.getBoundingClientRect().width) *
        100;
      let clickedTime =
        (parseInt(clickPosition, 10) * parseInt(playerRef?.duration(), 10)) /
        100;
      seekbarProgressRef.current.style.width = `${clickPosition}%`;
      if (seekTimeout.current) {
        clearTimeout(seekTimeout.current);
      }
      seekTimeout.current = setTimeout(() => {
        playerRef?.play();
        mouseTracker.current = false;
        playerRef?.seek(clickedTime);
      }, 500);
    }
  };

  const closeReportPopup = (e) => {
    e.stopPropagation();
    setShowReportIssue(false);
    playerRef?.play();
  };

  const onMouseMoveHandle = (e) => {
    e.stopPropagation();
    controlVisibility();
  };

  const wrapperClicked = (e) => {
    e.stopPropagation();
    setSubtitlePopup(false);
    setVolumeClicked(false);
  };

  const controlVisibility = () => {
    playerRef?.playerDom?.classList.remove("normal");
    playerRef?.playerDom?.classList.add("top");
    setShowControls(true);
    if (controlsTimeout.current) {
      clearTimeout(controlsTimeout.current);
    }
    controlsTimeout.current = setTimeout(() => {
      playerRef?.playerDom?.classList.remove("top");
      playerRef?.playerDom?.classList.add("normal");
      setShowControls(false);
    }, 5000);
  };

  const adCallback = (event) => {
    switch (event.type) {
      case "click":
        adStatus.current = true;
        break;
      case "adMetadata":
        break;
      case "loaded":
        adStatus.current = true;
        break;
      case "contentPauseRequested":
        // setAdStarted(true);
        setAdPlayStatus(true);
        break;
      case "adProgress":
        setAdProgress((event.j.currentTime / event.j.duration) * 100);
        setAdCurrentTime(toHHMMSS(event.j.duration - event.j.currentTime));
        break;
      case "start":
        setAdStarted(true);
        break;
      case "error":
      case "complete":
        adStatus.current = false;
        setAdStarted(false);
        break;
      case "contentResumeRequested":
        break;
    }
  };
  const adplaypause = (e) => {
    e.stopPropagation();
    if (!adPlayStatus) {
      setAdPlayStatus(true);
      adRef?.play();
    } else {
      setAdPlayStatus(false);
      adRef?.pause();
    }
  };

  return (
    <div
      role="none"
      className="player-page"
      id="player-container"
      onMouseMove={onMouseMoveHandle}
      onTouchStart={onMouseMoveHandle}
      onClick={wrapperClicked}
      style={{"overflow": "hidden"}}
      // onTouchEnd={wrapperClicked}
    >
      <div id="ad-container" className="ad-container" ref={adContainer}>
        {adStarted ? (
          <div className="ad-footer">
            <div className="seekbar-wrapper">
              {adPlayStatus ? (
                <span className="ad-play" onClick={adplaypause}></span>
              ) : (
                <span className="ad-pause" onClick={adplaypause}></span>
              )}
              <div className="seekbar" ref={adSeekbarRef}>
                <div
                  className="seekbar-progress"
                  style={{ width: `${adProgress}%` }}
                  ref={adSeekbarProgressRef}
                >
                  <span className="seek-ball"></span>
                </div>
              </div>
              <div className="timer">{adCurrentTime}</div>
            </div>
          </div>
        ) : null}
      </div>
      {showControls ? (
        <div className="player-header">
          <span role="none" className="back-btn" onClick={backClick}></span>
          <span role="none" className="title">
            {video_type == 2
              ? `${video_title} S${season_number}:E${episode_number}`
              : video_title || ""}
          </span>
          <span
            className="flag"
            role="none"
            onClick={(e) => {
              e.stopPropagation();
              playerRef?.pause();
              setShowReportIssue(true);
            }}
          ></span>
        </div>
      ) : null}
      {showEpisodeList && episodeDetailData && (
        <div className="list-container">
          <p>Season {season_number}</p>
          <div className="list">{createEpisodeList()}</div>
        </div>
      )}
      {showControls ? (
        <div className="player-footer">
          {isSkipIntro && content_type !== "trailer" ? (
            <div role="none" className="skip-intro" onClick={skipIntro}>
              Skip Intro
            </div>
          ) : null}
          {isNextVideo && content_type !== "trailer" ? (
            <div role="none" className="next-video" onClick={nextVideo}>
              Next Video
            </div>
          ) : null}
          <div className="seekbar-wrapper">
            <div className="current-timer">{currentTime}</div>
            <div
              role="none"
              className="seekbar"
              onClick={seekbarClick}
              ref={seekbarRef}
              onMouseMove={onMouseMoveHandler}
            >
              <div
                className="seekbar-progress"
                style={{ width: `${progress}%` }}
                ref={seekbarProgressRef}
              >
                <span
                  role="none"
                  className="seek-ball"
                  onMouseDown={onMouseEnterHandler}
                ></span>
              </div>
            </div>
            <div className="remaining-timer">{remainingTime}</div>
          </div>
          <div className="controls">
            <div className="control-left">
              {playStatus ? (
                <span role="none" className="play" onClick={playpause}></span>
              ) : (
                <span role="none" className="pause" onClick={playpause}></span>
              )}
              <span role="none" className="rewind" onClick={rewind}></span>
              <span role="none" className="forward" onClick={forward}></span>
              <div className="volume-control">
                {volumeClicked ? (
                  <div className="volume-slider-wrapper">
                    <div
                      role="none"
                      className="volume-wrapper"
                      tabIndex={0}
                      onClick={volumeSliderClick}
                    >
                      <div
                        className="volume-slider"
                        tabIndex={0}
                        style={{ height: `${sliderHeight}%` }}
                      ></div>
                    </div>
                  </div>
                ) : null}
                {!muteStatus ? (
                  <span role="none" className="volume" onClick={volume}></span>
                ) : (
                  <span role="none" className="mute" onClick={volume}></span>
                )}
              </div>
            </div>
            <div className="control-right">
              {/* <span className="next" onClick={nextVideo}></span> */}
              {video_type === "2" ? (
                <span
                  role="none"
                  className={`episodes ${showEpisodeList ? "active" : ""}`}
                  onClick={episodes}
                ></span>
              ) : null}
              <span
                role="none"
                className={`subtitle ${subtitlePopup ? "active" : ""}`}
                onClick={subtitleClick}
              ></span>
              <span
                className={fullscreen ? "exit-fullscreen" : "fullscreen"}
                role="none"
                onClick={(e) => {
                  e.stopPropagation();
                  if (fullscreen) {
                    setFullscreen(false);
                    document.exitFullscreen();
                  } else {
                    setFullscreen(true);
                    document.documentElement.requestFullscreen();
                  }
                }}
              ></span>
            </div>
          </div>
          {subtitlePopup ? (
            <div className="subtitle-audio">
              <div className="audio-container">
                <div className="title">Audio</div>
                <ul className="audio-list">
                  {video_languages && video_languages.length > 0
                    ? video_languages.map((item) => {
                        return (
                          <li
                            className="list-item active"
                            key={item.language_name}
                          >
                            <span className="check"></span>
                            {item.language_name || "default"}
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
              <div className="subtitle-container">
                <div className="title-wrapper">
                  <div className="title">Subtitle</div>
                  <div className="status">
                    {selectedSubtitle ? "on" : "off"}
                  </div>
                </div>
                <ul className="subtitle-list">
                  {subtitles && subtitles.length > 0
                    ? subtitles.map((item) => {
                        return (
                          <li
                            role="none"
                            className={`list-item ${
                              selectedSubtitle == item.language_short_name
                                ? "active"
                                : ""
                            }`}
                            key={item.language_short_name}
                            onClick={(event) => subtitleItemClick(event, item)}
                          >
                            <span className="check"></span>
                            <span>{item.language_name}</span>
                          </li>
                        );
                      })
                    : null}
                </ul>
              </div>
            </div>
          ) : null}
          {cueText ? <div className="subtitle-audio">{cueText}</div> : null}
        </div>
      ) : null}

      {loader ? (
        <div className="loader">
          <span className="spinner"></span>
        </div>
      ) : null}
      {/* {showPauseBanner ? (
        <PausedBanner
          backClick={backButtonClick}
          currentTime={remainingTimeSeconds}
        />
      ) : null} */}
      {showReportIssue ? (
        <ReportIssue closeReportPopup={closeReportPopup} />
      ) : null}
    </div>
  );
};

export default Player;
