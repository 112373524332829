import React from "react";
import topPicks1 from "../../assets/img/important-item/important-item-2.png";
import { IList, IListItem } from "../../model/bannerModel";
import { Link, useNavigate } from "react-router-dom";
import {
  CONTENT_DETAIL,
  CATEGORY,
  ORACLE,
  STILLLOOKINGCONTENT,
  COMINGSOON,
  PLAYER,
  PLAN_PAGE,
} from "../../routes/routesConstant";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/reducer";
import { MESSAGE } from "../../utils/constant";
import { setLeftSideMenuActive } from "../../redux/actions/headerAction";

const List = ({
  item,
  index,
  toggleWishlist,
  toggleRemoveWishlist,
  setLoginAlertBool,
  advancedSearchClick,
}: IList) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loginData } = useSelector<IRootState, any>(
    (state) => state.authReducer,
  );
  const { subscriptionData } = useSelector<IRootState, any>(
    (state) => state.profileReducer,
  );
  if (!item) return <></>;
  const getImage = (className: any, video: IListItem) => {
    switch (className) {
      case "portraitlarge_slider":
      case "portraitsmall_slider":
        return video["portrait_art_2000x3000"];
      case "landscapelarge_slider":
      case "landscapesmall_slider":
      case "importantitem_slider":
        return video["landscape_art_1280x720"];
      case "heroimage_slider":
        return video["portrait_ftm_art_1920x800"];
      default:
        return (
          video["landscape_art_1280×720"] || video["landscape_art_1280x720"]
        );
    }
  };

  const primaryClassName = (className: any) => {
    switch (className) {
      case "heroimage_slider":
        return "filmmatter_section";
      default:
        return "movie-section";
    }
  };
  const secondaryClassName = (className: any) => {
    switch (className) {
      case "importantitem_slider":
        return "importantitem_section";
      case "heroimage_slider":
        return "heroimage_section";
      default:
        return className;
    }
  };

  const stillLookingNavigation = (title: string) => {
    switch (title?.toLowerCase()?.replaceAll(/ /g, "")) {
      case "theoracle":
        return (
          <Link to={`${ORACLE}`}>
            <strong>{title}</strong>
          </Link>
        );
      case "advancedsearch":
        return (
          <strong
            onClick={() => {
              dispatch(setLeftSideMenuActive(true));
              advancedSearchClick();
            }}
          >
            {title}
          </strong>
        );
      case "trending":
      case "mostviewed":
      case "mostshared":
      case "filmsthatmatter":
      case "remasteredclassics":
        return (
          <Link to={`${STILLLOOKINGCONTENT}?category=${title}`}>
            <strong>{title}</strong>
          </Link>
        );
      default:
        return <strong>{title}</strong>;
    }
  };

  const fetchRoute = (item: any) => {
    if (item.genre_name?.toLowerCase()?.replaceAll(/ /g, "") === "comingsoon") {
      return `${COMINGSOON}`;
    }
    return `${CATEGORY}?${item.genre_id || item.id ? `category_id=${item.genre_id || item.id}` : ""}&category_name=${item.genre_name || item.list_name}&type=genre${item.genre_type ? `&genre_type=${item.genre_type}` : ""}${item.list_name || item.genre_name?.toLowerCase()?.replaceAll(/ /g, "") === "continuewatch" ? `&category_type=${item.list_name?.toLowerCase()?.replaceAll(/ /g, "") || item.genre_name?.toLowerCase()?.replaceAll(/ /g, "")}` : ""}`;
  };

  if (Array.isArray(item)) {
    return (
      <section className="helpfultools_section">
        <div className="container-fluid">
          <div className="helpfultools_main">
            <h2>Still looking? Check out these helpful tools.</h2>
            {item.map((itemData) => (
              <div className="helpfultools_grid" key={itemData.id}>
                <div className="helpfultools_group">
                  <div className="media helpfultools_content">
                    <div className="media-body">
                      <a href="oraclestep1.php">
                        <img src={itemData.icon} alt="the-oracle" />
                      </a>
                    </div>
                    <p>
                      {stillLookingNavigation(itemData.title)} -
                      {itemData.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
  return (
    <section
      className={`${primaryClassName(item.genre_class)} ${secondaryClassName(item.genre_class)}`}
      key={`item-${index}`}
    >
      <div className="container-fluid">
        {item.genre_class !== "importantitem_slider" &&
        item.genre_class !== "heroimage_slider" ? (
          <div className="movie_main">
            {item.genre_name?.toLocaleLowerCase() !== "browse all" && (
              <div className="title">
                <span>
                  <i>
                    <img src={item.genre_icon || ""} />
                  </i>{" "}
                  {item.genre_name || item.list_name}
                </span>
                <Link to={fetchRoute(item)} className="viewall">
                  View All {item.genre_name || item.list_name}
                </Link>
              </div>
            )}
            <div
              className={`moviecommon-slider owl-carousel ${item.genre_class || "portraitlarge_slider"}`}
            >
              {item.genre_name?.toLocaleLowerCase() === "browse all" && (
                <div className="movie-panel browseall_catpanel">
                  <div className="movi-poster">
                    <img src={item.background_image} alt="new-arrivals-1" />
                    <div
                      className="movishortinfo_panel browseall_cat"
                      style={{ paddingTop: "8px" }}
                    >
                      <div className="browseall_contentcover">
                        <div className="browseall_content">
                          <h3>{item.title}</h3>
                          <p>{item.tagline}</p>
                        </div>
                        <Link
                          to={`${CATEGORY}?category_id=${item.genre_id}&category_name=${item.genre_name || item.list_name}&type=genre&genre_type=${item.genre_type}&title=${item.title}`}
                          className="imprtitem_moreinfo"
                        >
                          BROWSE ALL
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {item?.videos?.length &&
                item.videos?.map((video: IListItem, index: number) => (
                  <div className="movie-panel" key={video.video_id}>
                    <div
                      className="movi-poster"
                      key={`detail-${video.video_id}`}
                    >
                      <Link
                        to={`${CONTENT_DETAIL}?video_id=${video.video_id}&video_type=${video.video_type}`}
                        className="movieBox"
                      >
                        <img
                          src={getImage(item?.genre_class, video)}
                          alt="top-picks-1"
                        />
                      </Link>
                      {video?.play_time ? (
                        <div className="seekbar_panel">
                          <div
                            className="seekbar_fill"
                            style={{
                              width: `${video ? (video?.play_time / video?.total_time) * 100 : 0}%`,
                            }}
                          ></div>
                        </div>
                      ) : null}
                      <div className="movishortinfo_panel">
                        <span>
                          <i
                            className="fa fa fa-play"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Play"
                            onClick={() => {
                              if (!loginData?.token) {
                                setLoginAlertBool(
                                  MESSAGE.CREATE_ACCOUNT_TO_PLAY_CONTENT,
                                );
                                // return;
                              } else {
                                if (
                                  subscriptionData?.subscription_status !=
                                    "Active" &&
                                  subscriptionData?.subscription_status !=
                                    "Cancelled" &&
                                  video.is_premium == "1"
                                ) {
                                  navigate(`${PLAN_PAGE}`);
                                  return;
                                }
                                navigate(
                                  `${PLAYER}?video_id=${video.video_id}&video_type=${video.video_type}${video.seasion_id ? "&season_id=" + video.seasion_id : ""}${video.episode_id ? "&episode_id=" + video.episode_id : ""}`,
                                );
                              }
                            }}
                          ></i>
                        </span>
                        <span className="sesonepsd_text">
                          {video.video_type === 2
                            ? `S${video.seasion_number}E${video.episode_number}`
                            : null}
                        </span>
                        <span>
                          {video.is_my_list || item.list_name ? (
                            <i
                              className="fa fa-check"
                              data-toggle="modal"
                              data-target="#wishlist-popup"
                              data-whatever="@wishlist-popup"
                              data-placement="bottom"
                              title="Remove from watchlist"
                              onClick={() =>
                                toggleRemoveWishlist(
                                  true,
                                  video.video_id,
                                  video.watch_list_id,
                                )
                              }
                            ></i>
                          ) : (
                            <i
                              className="fa fa-plus"
                              data-toggle="modal"
                              data-target="#wishlist-popup"
                              data-whatever="@wishlist-popup"
                              data-placement="bottom"
                              title="Add to watchlist"
                              onClick={() => {
                                if (!loginData?.token) {
                                  setLoginAlertBool(
                                    MESSAGE.LOGIN_TO_ADD_TO_WISHLIST,
                                  );
                                  return;
                                }
                                toggleWishlist(
                                  true,
                                  getImage(item?.genre_class, video),
                                  video.video_id,
                                );
                              }}
                            ></i>
                          )}
                        </span>
                      </div>
                    </div>
                    {item.genre_name?.toLowerCase()?.replaceAll(/ /g, "") ===
                    "lastcall" ? (
                      <div className="movishortstatus_panel">
                        <a href="">
                          <span>{video?.last_call_name}</span>
                          {/* <span><strong>#1</strong> Trending</span> */}
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ) : item.genre_class === "heroimage_slider" ? (
          <div className="filmmatter_main">
            <div className="title">
              <i>
                <img
                  style={{ height: "50px", width: "50px" }}
                  src={item.genre_icon || ""}
                />
              </i>
              <h2>{item.genre_name || item.list_name} </h2>
              <Link
                to={`${CATEGORY}?category_id=${item.genre_id}&category_name=${item.genre_name || item.list_name}&type=genre&genre_type=${item.genre_type}`}
                className="viewall"
              >
                View All {item.genre_name || item.list_name}
              </Link>
            </div>
            <div className="filmmatter_slider heroimage_slider owl-carousel">
              {item.videos?.length &&
                item.videos?.map((video: IListItem, index: number) => (
                  <div className="filmmatter_slide" key={video.video_id}>
                    <Link
                      to={`${CONTENT_DETAIL}?video_id=${video.video_id}&video_type=${video.video_type}`}
                      className="movieBox"
                    >
                      <img
                        src={getImage(item?.genre_class, video)}
                        alt="filmmatter"
                      />
                    </Link>
                    <div className="filmmatter-text">
                      <div className="media filmmatter_content">
                        <div className="media-body">
                          <div className="filmmatter_shortinfo">
                            <h3>{video.video_title}</h3>
                            <span>
                              Directed by{" "}
                              {video?.directors
                                ?.map(
                                  (user: {
                                    director_description: string;
                                    director_id: number;
                                    director_image: null;
                                    director_name: string;
                                    director_slug: string;
                                    video_id: number;
                                  }) => user.director_name,
                                )
                                .join(",")}
                            </span>
                          </div>
                        </div>
                        <div className="filmmatter_icon">
                          <span>
                            <i
                              className="fa fa fa-play"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Play"
                              onClick={() => {
                                if (!loginData?.token) {
                                  setLoginAlertBool(
                                    MESSAGE.LOGIN_TO_PLAY_CONTENT,
                                  );
                                  // return;
                                } else {
                                  navigate(
                                    `${PLAYER}?video_id=${video.video_id}&video_type=${video.video_type}`,
                                  );
                                }
                              }}
                            ></i>
                          </span>
                          <span>
                            {video.is_my_list ? (
                              <i
                                className="fa fa-check"
                                data-toggle="modal"
                                data-placement="bottom"
                                title="Remove from watchlist"
                                onClick={() =>
                                  toggleRemoveWishlist(
                                    true,
                                    video.video_id,
                                    video.watch_list_id,
                                  )
                                }
                              ></i>
                            ) : (
                              <i
                                className="fa fa-plus"
                                data-toggle="modal"
                                data-placement="bottom"
                                title="Add to watchlist"
                                onClick={() => {
                                  if (!loginData?.token) {
                                    setLoginAlertBool(
                                      MESSAGE.LOGIN_TO_ADD_TO_WISHLIST,
                                    );
                                    return;
                                  }
                                  toggleWishlist(
                                    true,
                                    getImage(item?.genre_class, video),
                                    video.video_id,
                                  );
                                }}
                              ></i>
                            )}
                          </span>
                          <span>
                            <Link
                              to={`${CONTENT_DETAIL}?video_id=${video.video_id}&video_type=${video.video_type}`}
                              className="common-btn details_btn"
                            >
                              {" "}
                              DETAILS
                            </Link>
                          </span>
                        </div>
                      </div>
                      <p>{video.short_description}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className="importantitem_main">
            <div className="importantitem_slider moviecommon-slider owl-carousel">
              {item.videos?.length &&
                item.videos?.map((video: IListItem) => (
                  <div className="importantitem_group" key={video?.video_id}>
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="importantitem_imgpanel">
                          <img src={getImage(item?.genre_class, video)} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="importantitem_textpanel">
                          <h2>{video.video_title}</h2>
                          <h3>
                            {" "}
                            {video?.directors
                              ?.map(
                                (user: {
                                  director_id: number;
                                  director_name: string;
                                }) => user.director_name,
                              )
                              .join(",")}
                          </h3>
                          {/* <h3>{video.video_title}</h3> */}
                          <p>{video.short_description}</p>
                          <div className="media">
                            <span style={{ marginRight: "10px" }}>
                              <i
                                className="fa fa fa-play"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Play"
                                onClick={() => {
                                  if (!loginData?.token) {
                                    setLoginAlertBool(
                                      MESSAGE.LOGIN_TO_PLAY_CONTENT,
                                    );
                                    // return;
                                  } else {
                                    navigate(
                                      `${PLAYER}?video_id=${video.video_id}&video_type=${video.video_type}`,
                                    );
                                  }
                                }}
                              ></i>
                            </span>
                            <span>
                              {video.is_my_list ? (
                                <i
                                  className="fa fa-check"
                                  data-toggle="modal"
                                  data-placement="bottom"
                                  title="Remove from watchlist"
                                  onClick={() =>
                                    toggleRemoveWishlist(
                                      true,
                                      video.video_id,
                                      video.watch_list_id,
                                    )
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-plus"
                                  data-toggle="modal"
                                  data-placement="bottom"
                                  title="Add to watchlist"
                                  onClick={() => {
                                    if (!loginData?.token) {
                                      setLoginAlertBool(
                                        MESSAGE.LOGIN_TO_ADD_TO_WISHLIST,
                                      );
                                      return;
                                    }
                                    toggleWishlist(
                                      true,
                                      getImage(item?.genre_class, video),
                                      video.video_id,
                                    );
                                  }}
                                ></i>
                              )}
                            </span>
                            <span className="media-body">
                              <Link
                                to={`${CONTENT_DETAIL}?video_id=${video.video_id}&video_type=${video.video_type}`}
                                className="imprtitem_moreinfo"
                              >
                                DETAILS
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default React.memo(List);
