import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../Component/Header';
import { useSearchParams } from 'react-router-dom';
import Footer from '../../Component/Footer';
import { setLeftSideMenuActive, setRightSideMenuActive } from '../../redux/actions/headerAction';
import { fetchSearchItemRequest, getSearchListRequest, saveSearchQueryRequest, searchListEmpty } from '../../redux/actions/searchAction';
import { ICreateListState } from '../../model/createList';
import { IRootState } from '../../redux/reducer';
import { IHeaderinitialState } from '../../redux/reducer/headerReducer';


import TextView from './textView';
import GridView from './gridView';
import SearchSavePopup from '../../Component/Popup/searchSavePopup';
import LoginAlertPopup from '../../Component/Popup/loginAlertPopup';

declare global {
    interface Window {
        $: any;
        end: any;
    }
}

const Search = () => {
    const dispatch = useDispatch();
    const [range, setRange] = useState(0);
    const [loginAlertBool, setLoginAlertBool] = React.useState('');
    const [savePopup, setSavePopup] = React.useState(false);
    const [query, setQuery] = React.useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const { left_active, right_active } = useSelector<IRootState, IHeaderinitialState>((state) => state.headerReducer);
    const $ = window.$;
    const view_type: string | null = searchParams.get("viewType") || '';
    const era: string | null = searchParams.get("era") || '';
    const genre: string | null = searchParams.get("genre") || '';
    const region: string | null = searchParams.get("region") || '';
    const showText: string | null = searchParams.get("showText") || '';
    const searchId: string | null = searchParams.get("searchId") || '';
    const searchName: string | null = searchParams.get("searchName") || '';

    const { loginData } = useSelector<IRootState, any>((state) => state.authReducer);
    const { dev_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);

    const { search_result, searchItemList, count } = useSelector<IRootState, any>((state) => state.searchReducer);
    const { createVideoListBool, message } = useSelector<IRootState, ICreateListState>((state) => state.createListReducer);
    const getPaginationData = () => {
        setRange(range + 1);
    };
    useEffect(() => {
        dispatch(searchListEmpty());
    }, []);
    useEffect(() => {
        if (left_active)
            dispatch(setLeftSideMenuActive(false))
        if (!dev_base_url)
            return
        if (showText === '1') {
            dispatch(getSearchListRequest({ range, showText, view_type, user_id: loginData?.user_id || '', query }));
        } else if (!searchId) {
            dispatch(getSearchListRequest({ range, view_type, era, genre, region, user_id: loginData?.user_id || '' }));
        } else if (searchId) {
            dispatch(fetchSearchItemRequest(loginData?.user_id || '', searchId, range));
        }
    }, [range, view_type, era, genre, region, loginData?.user_id, showText, dev_base_url, createVideoListBool, message]);

    useEffect(() => {
        if (!dev_base_url)
            return
        if (showText === '1') {
            dispatch(getSearchListRequest({ range, showText, view_type, user_id: loginData?.user_id || '', query }));
        }
    }, [query]);

    useEffect(() => {
        if (!dev_base_url)
            return
        if (searchId) {
            dispatch(fetchSearchItemRequest(loginData?.user_id || '', searchId, range));
        }
    }, [searchId]);

    const onQueryChange = (value: string) => {
        setQuery(value);
    };

    const savePopUp = (name: string) => {
        dispatch(saveSearchQueryRequest({ user_id: loginData?.user_id || '', query, genre, name, region_id: region, view_type: view_type, era }));
        setSavePopup(false);
    }

    return (
        <>
            <Header />
            {loginAlertBool ? <LoginAlertPopup setLoginAlertBool={setLoginAlertBool} loginAlertBool={loginAlertBool} message={loginAlertBool} /> : <></>}

            {savePopup ? <SearchSavePopup setSavePopup={setSavePopup} savePopUp={savePopUp} /> : <></>}
            <div className="searchresultsml_page">
                {view_type === "custom_grid" || searchName ? <GridView setLoginAlertBool={setLoginAlertBool} setSavePopup={setSavePopup} showText={showText} data={searchName ? searchItemList ?? [] : search_result} count={count} searchName={searchName} onQueryChange={onQueryChange} query={query} getPaginationData={getPaginationData} /> : <TextView setLoginAlertBool={setLoginAlertBool} setSavePopup={setSavePopup} data={search_result} count={count} getPaginationData={getPaginationData} />}
            </div>
            <Footer />
            <div className="whiteOverlay2" onClick={() => dispatch(setLeftSideMenuActive(false))} style={{ display: left_active ? 'block' : '' }}></div>
            <div className="whiteOverlay3" onClick={() => dispatch(setRightSideMenuActive(false))} style={{ display: right_active ? 'block' : '' }}></div>
        </>)
}

export default Search;